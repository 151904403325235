<!--<div class="faq-section">
  <section>

    <div class="left-side">
      <div class="top-part">
        <div class="blue-small-desc">
          We collected for you question that can help you
        </div>

        <h2 class="left-side-question" [innerHTML]="faq?.title"></h2>
      </div>

      <div class="content-part">
        <div class="list">

          <ng-container *ngFor="let item of faq?.items">
            <div
              class="list-item"
              [class.active]="item === selectedInfo"
              (click)="onSelectItem(item)">
              {{ item.title }}
            </div>

            <div class="right-side small-screen" *ngIf="item === selectedInfo">
              <h2>Resume builders such as selfcv</h2>
              <p>{{ selectedInfo.info }}</p>
            </div>
          </ng-container>

        </div>
      </div>
    </div>

    <div class="right-side">
      <h2>Resume builders such as selfcv</h2>
      <p>{{ selectedInfo?.info }}</p>
    </div>
  </section>
</div>-->


<div class="faq-section">
  <div class="FAQ-section">

    <div class="top-part mb-30">
      <div class="blue-small-desc">
        We collected for you question that can help you
      </div>

      <h2 class="left-side-question" [innerHTML]="faq?.title"></h2>
    </div>

    <nz-collapse class="FAQ-nz-collapse" [nzExpandIconPosition]="'end'">
      @for (item of faq?.items; track item.title) {
        <nz-collapse-panel [nzHeader]="item.title">
          <p class="FAQ-content-item">
            {{ item.info }}
          </p>
        </nz-collapse-panel>
      }
    </nz-collapse>
  </div>

</div>
